import React from 'react';
import "./header.css"
const Header = () => {
  return (
    <header className="bg-primary-main">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="text-start pb-4">
              <h1 className="text-light  fw-bold header-title fs-1">
                فروشگاه اینترنتی ماشین نو
              </h1>
            </div>
            <ul className="nav flex-column mt-3" style={{marginRight: 20}}>
              <li className="nav-item mb-2 text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-caret-left-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
                </svg>
                بیش از چند میلیون بازدید سالانه
              </li>
              <li className="nav-item mb-2 text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-caret-left-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
                </svg>
                گزارش فروش و درآمد شفاف
              </li>
              <li className="nav-item mb-2 text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-caret-left-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
                </svg>
                امکان ارسال کالا توسط ماشین نو و فروشنده
              </li>
              <li className="nav-item mb-2 text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-caret-left-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
                </svg>
                ثبت نام رایگان به همراه آموزش
              </li>
              <li className="nav-item mb-2 text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-caret-left-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
                </svg>
                تبلیغات رایگان برای بازدید بیشتر محصول شما
              </li>
              <li className="nav-item mb-2 text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-caret-left-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
                </svg>
                فروش آنلاین در هر نقطه از ایران
              </li>

            </ul>
          </div>
          <div className="col-lg-6">
            <img
              src="/landing/1482.png"
              alt=""
              className="img-fluid img-header"
            />
          </div>
        </div>
      </div>
      <div className="waveWrapper waveAnimation">
        <div className="waveWrapperInner bottom">
          <div
            className="wave waveTop top"
            style={{backgroundImage: "url(landing/wave-bot.png)"}}
          />
          <div
            className="wave waveMid mid"
            style={{backgroundImage: "url(landing/wave-bot.png)"}}
          />
          <div
            className="wave waveBottom bottom"
            style={{backgroundImage: "url(landing/wave-bot.png)"}}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;