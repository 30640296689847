import React, {useEffect, useState} from 'react';
import {Paginator} from "../../../../Infrastructure/services/Types/Paginator";
import {webRequest} from "../../../../Infrastructure/services/apiService";
import {validateResponse} from "../../../../Infrastructure/services/validateResponse";
import ClickablePagination from "../../../Ui/ClickablePagination";
import AccountingHistorySkeleton from "./AccountingHistorySkeleton";
import useAppContext from "../../../../Infrastructure/state/useAppContext";


interface IRecord {
  id: number,
  createdAt: string,
  mainAmount: string,
  mashinno: string,
  vendor: string,
}

const AccountingHistory = () => {

  let [records, setRecords] = useState<Paginator<IRecord> | undefined>(undefined)
  let {state}=useAppContext()
  let [page,setPage] = useState<number>(1)
  let [loaded,setLoaded] = useState<boolean>(false)

  useEffect(() => {
    getData(1)
  }, []);


  let pageClicked=(page:any)=>{
    setPage(page)
    getData(page)
  }


  let getData=(page:any)=>{
    setLoaded(false)
    webRequest({
      url: "/accounting/history",
      data:{
        page
      },
      auth:state.auth,
    }).then(response => {
      try {
        validateResponse(response)
        setRecords(response.payload)
        setLoaded(true)
      } catch (e: any) {
      }
    })
  }



  return (
    <div className={"card p-3"}>
      {loaded && <div className={"table-responsive"}>
        <table className="table table-bordered tac">

          <thead>
          <tr>
            <td>شماره سفارش</td>
            <td>تاریخ ثبت</td>
            <td>مبلغ فاکتور</td>
            <td>کارمزد ماشین نو</td>
            <td>فروشنده</td>
          </tr>
          </thead>

          <tbody>
          {records?.records.map((row: IRecord, index: number) => {
            return <tr key={index}>
              <td>{row.id}</td>
              <td>{row.createdAt}</td>
              <td>{row.mainAmount}</td>
              <td>{row.mashinno}</td>
              <td>{row.vendor}</td>
            </tr>
          })}
          </tbody>

        </table>
      </div>}

      {!loaded && <AccountingHistorySkeleton/>}
      <ClickablePagination lastPage={records?.lastPage} currentPage={page} total={records?.total} clicked={pageClicked}/>

    </div>
  );
};

export default AccountingHistory;