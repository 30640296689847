import React, {useEffect, useState} from 'react';
import 'react-loading-skeleton/dist/skeleton.css'
import {webRequest} from "../../../Infrastructure/services/apiService";
import useAppContext from "../../../Infrastructure/state/useAppContext";
import ApexCharts from 'apexcharts'
import Skeleton from "react-loading-skeleton";
import {validateAuth, validateResponse} from "../../../Infrastructure/services/validateResponse";
import xToast from "../../../commons/xToast";

const Index = () => {
  let {state, dispatch} = useAppContext()

  let [loaded, setLoaded] = useState(false)

  let defaultData = {
    chart: {
      type: 'area',
      height: 400,
      stroke: {
        curve: 'smooth',
      }
    },

    series: [{
      name: "test",
      data: []
    }],

    options: {
      chart: {
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        categories: []
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
    },
  }


  useEffect(() => {

    if (!state.auth) return;

    webRequest({
      url: "/home",
      auth: state.auth
    }).then(res => {
      try {
        validateAuth(res,dispatch)
        validateResponse(res)
        initMonthly(res.payload.monthly)
        initDaily(res.payload.daily)
        setLoaded(true)
      } catch (e: any) {
        xToast.error(e.message)
      }

    })
  }, [state.auth]);


  const initMonthly = (data: any) => {

    let chart = new ApexCharts(document.querySelector("#monthly"), {
      ...defaultData,
      series: [data.series],
      options: {
        ...defaultData.options,
      }
    });
    chart.render();
  }

  const initDaily = (data: any) => {

    // let chart = new ApexCharts(document.querySelector("#daily"), {
    //   ...defaultData,
    //   series: [data.series],
    //   options: {
    //     ...defaultData.options,
    //   }
    // });
    // chart.render();
  }


  return (
    <>
      <div className={"card"}>
        <div className={"p-2"} style={{background: "#7367f0", color: "#fff", borderTopLeftRadius: "8px", borderTopRightRadius: "8px", paddingRight: "24px"}}>
          آمار فروش ماهانه
        </div>
        <hr style={{marginTop: "0"}}/>
        <div className={"row"}>
          <div className={""}>
            {!loaded && <Skeleton width={"100%"} height={400}/>}
          </div>
          <div id="monthly" className={"col-12"}></div>
        </div>
      </div>

      {/*<div className={"card mt-3"}>*/}
      {/*  <div className={"p-2"} style={{background: "#7367f0", color: "#fff", borderTopLeftRadius: "8px", borderTopRightRadius: "8px", paddingRight: "24px"}}>*/}
      {/*    آمار فروش روزانه*/}
      {/*  </div>*/}
      {/*  <hr/>*/}
      {/*  <div className={"row"}>*/}
      {/*    <div className={""}>*/}
      {/*      {!loaded && <Skeleton width={"100%"} height={400}/>}*/}
      {/*    </div>*/}
      {/*    <div id="daily" className={"col-12"}></div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>

  );
};

export default Index;