import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import "./fixedHeader.css"

const FixedHeader = () => {

  // let [width, setWidth] = React.useState(0);

  // useEffect(() => {
  //
  //   window.scrollTo(0, 0);
  //
  // }, [])
  //
  // const initSize=()=>{
  //   if (window.innerWidth > 800) {
  //     setWidth(190);
  //   } else {
  //     setWidth(100);
  //   }
  // }

  return (
    <div className="" style={{zIndex: "99999", background: "#fff", position: 'fixed', width: "100%", height: "80px", borderBottom: "1px solid #eee", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 32px"}}>
      <div>
        <img
          alt="mashinno logo"
          src="https://mashinno.com/uploads/settings/settings-13-color.png?c=0"
          className="main-logo"
        />
      </div>
      <div className="main-header-left">
        <span className={"main-header-text"}>ورود به پنل فروشندگان</span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Link to="/auth">
          <button className="btn btn-primary" style={{width: 100}}>
            ورود
          </button>
        </Link>
      </div>
    </div>

  );
};

export default FixedHeader;