import React from 'react';
import "./footer.css"

const Footer = () => {
  return (
    <footer className="x-footer footer-pb">
      <div className="container">
        <address className="footer-social">
          <div className="row gy-3">
            <div className="col-lg-3">
              <div className="contact-item">
                <div className="title">
                  <i className="bi bi-telephone-fill main-color-one-color"/>
                  <div className="font-16 pt-1">
                    <span className="main-color-one-color">شماره </span>ارتباطی
                  </div>
                </div>
                <div className="desc">
                  <a href="tel:02191301374" className="text-muted">
                    02191301374{/* */}&nbsp;
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="contact-item">
                <div className="title">
                  <i className="bi bi-envelope-fill main-color-one-color"/>
                  <div className="font-16 pt-1">
                    <span className="main-color-one-color"> ایمیل </span>ارتباطی
                  </div>
                </div>
                <div className="desc">
                  <a href="mailto:info@mashinno.com" className="text-muted">
                    info@mashinno.com
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="contact-item">
                <div className="title">
                  <i className="bi bi-geo-alt-fill main-color-one-color"/>
                  <div className="font-16 pt-1">
                    <span className="main-color-one-color"> آدرس </span>شرکت
                  </div>
                </div>
                <div className="desc">
              <span className="text-muted">
                آدرس : تهران ،خیابان جمهوری ، نرسیده به خیابان ملت ، کوچه مراغه
                ای ، بن بست یاس شرقی پلاک ۱۱
              </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="contact-item">
                <div className="title" style={{display:"flex",alignItems:"center",justifyContent:"center",direction:"rtl"}}>
                  <i className="ti ti-globe main-color-one-color" style={{marginRight:"0"}}/>
                  <div className="font-16 pt-1 ">
                    مارا در
                    <span className="main-color-one-color"> شبکه های اجتماعی </span>
                    دنبال کنید
                  </div>
                </div>
                <div className="desc" style={{textAlign:"center"}}>
                  <div className="social tac">
                    <a href="https://wa.me/989120151169"  title="واتس اپ">
                      <i className="ti ti-brand-whatsapp"/>
                    </a>
                    <a
                      href="https://www.instagram.com/mashinnocom/"
                      title="اینستاگرام"
                    >
                      <i className="ti ti-brand-instagram"/>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/mashinno/"
                      title="linkedin"
                    >
                      <i className="ti ti-brand-linkedin"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </address>
        <nav className="footer-menu">
          <div className="row gy-3">
            <div className="col-lg-3 col-6">
              <div className="footer-menu-title">
                <div className="font-16 pt-1" style={{color:"#000 !important;",fontSize:"19px"}}>دسترسی سریع</div>
                <ul className="navbar-nav">
                  <li className="nav-item lh20">
                    <a className="nav-link" href="https://mashinno.com/pages/contact-us">
                      تماس با ما
                    </a>
                  </li>
                  <li className="nav-item lh20">
                    <a
                      className="nav-link"
                      href="https://mashinno.com/pages/%D8%AF%D8%B1%D8%A8%D8%A7%D8%B1%D9%87-%D9%85%D8%A7"
                    >
                      درباره ماشین‌نو
                    </a>
                  </li>
                  <li className="nav-item lh20">
                    <a
                      className="nav-link"
                      href="https://mashinno.com/pages/%D9%82%D9%88%D8%A7%D9%86%DB%8C%D9%86-%D9%88-%D9%85%D9%82%D8%B1%D8%B1%D8%A7%D8%AA"
                    >
                      قوانین و مقررات
                    </a>
                  </li>
                  <li className="nav-item lh20">
                    <a
                      className="nav-link"
                      href="https://mashinno.com/pages/%D8%B4%D8%B1%D8%A7%DB%8C%D8%B7-%D8%A8%D8%A7%D8%B2%DA%AF%D8%B4%D8%AA-%DA%A9%D8%A7%D9%84%D8%A7"
                    >
                      شرایط بازگشت کالا
                    </a>
                  </li>
                  <li className="nav-item lh20">
                    <a className="nav-link" href="https://mashinno.com/blog/">
                      وبلاگ
                    </a>
                  </li>
                  <li className="nav-item lh20">
                    <a
                      className="nav-link"
                      href="https://mashinno.com/landings/credit"
                    >
                      فروش اعتباری
                    </a>
                  </li>
                  <li className="nav-item lh20">
                    <a className="nav-link" href="https://app.mashinno.com">
                      اپلیکیشن ماشین نو
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="footer-menu-title">
                <div className="font-16 pt-1"  style={{color:"#000 !important;",fontSize:"19px"}}>لوازم یدکی</div>
                <ul className="navbar-nav">
                  <li className="nav-item lh20">
                    <a className="nav-link" href="https://mashinno.com/pages/shop-ssangyong">
                      لوازم یدکی سانگ یانگ
                    </a>
                  </li>
                  <li className="nav-item lh20">
                    <a className="nav-link" href="https://mashinno.com/pages/shop-geely">
                      لوازم یدکی جیلی
                    </a>
                  </li>
                  <li className="nav-item lh20">
                    <a className="nav-link" href="https://mashinno.com/pages/shop-hyundai">
                      لوازم یدکی هیوندای
                    </a>
                  </li>
                  <li className="nav-item lh20">
                    <a className="nav-link" href="https://mashinno.com/pages/shop-kia">
                      لوازم یدکی کیا
                    </a>
                  </li>
                  <li className="nav-item lh20">
                    <a className="nav-link" href="https://mashinno.com/pages/shop-brilliance">
                      لوازم یدکی برلیانس
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="footer-menu-title">
                <div className="font-16 pt-1"  style={{color:"#000 !important;",fontSize:"19px"}}>لینک های سریع</div>
                <ul className="navbar-nav">
                  <li className="nav-item lh20">
                    <a
                      className="nav-link"
                      href="https://mashinno.com/pages/%D9%81%D8%B1%D8%B5%D8%AA-%D9%87%D8%A7%DB%8C-%D8%B4%D8%BA%D9%84%DB%8C"
                    >
                      فرصت های شغلی
                    </a>
                  </li>
                  <li className="nav-item lh20">
                    <a
                      className="nav-link"
                      href="https://vendor.mashinno.com/register"
                    >
                      فروشنده شو
                    </a>
                  </li>
                  <li className="nav-item lh20">
                    <a className="nav-link" href="https://mashinno.com/inquiry">
                      خدمات خودرویی
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        <div className="footer-text">
          <div className="row gy-3">
            <div className="col-lg-9">
              <div className="footer-menu-text">
                <section className="home-about-us py-20 mt-5">
                  <div className="container-fluid">
                    <article className="">
                      <h2 className="fsh2">خرید اینترنتی لوازم یدکی خودرو</h2>
                      <p style={{textAlign:"justify"}}>
                    <span>
                      <span>در </span>
                    </span>
                        <a
                          className="span-primary me-2"
                          href="https://mashinno.com"
                        >
                          فروشگاه اینترنتی لوازم یدکی ماشین نو
                        </a>
                        <span>
                      تمام قطعات مورد نیاز برای انواع مدل خودرو موجود در بازار
                      با بهترین قیمت در دسترس شما قرار گرفته است. کافی است با
                      توجه به مدل خودروی خود به دسته‌بندی مربوطه مراجعه کنید یا
                      در کادر جستجوی سایت، قطعه مورد نیازتان را جستجو کنید.
                    </span>
                      </p>

                    </article>
                  </div>
                </section>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-namad" style={{display:"flex",justifyContent:"center",marginBottom:"32px"}}>
                <a href="https://trustseal.enamad.ir/?id=94636&Code=0M5azs8VMWm4TOvspHJX">
                  <picture className="overflow-hidden">
                    <img
                      className="img-thumbnail"
                      src="https://mashinno.com/cdn/images/e-namad.webp"
                      alt="ای نماد"
                      width={140}
                      height={140}
                    />
                  </picture>
                </a>
                &nbsp;
                &nbsp;
                <a href="https://ecunion.ir/verify/mashinno.com?token=898916061ac5a3405ee4">
                  <picture className="overflow-hidden">
                    <img
                      className="img-thumbnail"
                      src="https://mashinno.com/cdn/images/kasbokar.jpg"
                      alt="کسب و کار"
                      width={140}
                      height={140}
                    />
                  </picture>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="footer-copy-right" style={{background:"#eee",padding:"8px"}}>
          <p className="text-center mb-0">
            تمامی حقوق مادی و معنوی این وب سایت برای ماشین نو محفوظ می باشد ، هر
            گونه کپی برداری از سایت پیگرد قانونی دارد . Copyright ©2015-2024
          </p>
        </div>
      </div>
    </footer>

  );
};

export default Footer;