export const buildSelectCategoryCar = (category: any, props: any, car: any, brand: any) => {
  let brandSelectValue = null
  let carSelectValue = null
  let categorySelectValue = null
  let cars = props.cars

  if (category && category > 0 && props.categories && props.categories.length > 0) {
    for (let i = 0; i < props.categories.length; i++) {
      if (props.categories[i].value === category) categorySelectValue = props.categories[i]
    }
  }

  if (car && car > 0 && props.cars && props.cars.length > 0) {
    for (let i = 0; i < props.cars.length; i++) {
      if (props.cars[i].value === car) carSelectValue = props.cars[i]
    }
  }


  if (brand && brand > 0 && props.brands && props.brands.length > 0) {
    let temp = [

    ];
    temp.push({
      value: 0,
      label: "همه خودرو ها",
      brand_id: "",
    })


    for (let i = 0; i < props.cars.length; i++) {
      if (cars[i].brandId === brand) {
        temp.push(props.cars[i]);
      }
    }

    cars = temp

    for (let i = 0; i < props.brands.length; i++) {
      if (props.brands[i].value === brand) brandSelectValue = props.brands[i]
    }
  }


  return {
    brandSelectValue, carSelectValue, categorySelectValue,
    cars
  }


}


export const buildProductPageUri = (brand: number, car: number, category: number, page: number, keyword: string, type: any) => {
  let url ;

  if (type=="mine") {
    url = "/products/mine?"
  }else{
    url = "/products?"
  }

  if (brand > 0) url += "brand=" + brand + "&"
  if (car > 0) url += "car=" + car + "&"
  if (category > 0) url += "category=" + category + "&"
  if (page > 1) url += "page=" + page + "&"
  if (keyword) url += "q=" + keyword + "&"

  let len = url.length
  if (url.substring(len - 1, len) === "&" || url.substring(len - 1, len) === "?") {
    url = url.substring(0, len - 1)
  }

  return url
}