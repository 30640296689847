import React from 'react';

interface IGuide {
  icon: string,
  title: string,
  description: string,
}

const GuideRow = (props: IGuide) => {
  return (
    <div className="col-md-4 mb-5 pb-4">
      <div className="tac mb-3">
        <i className={props.icon} style={{fontSize:"30px"}}/>
      </div>
      <div className="tac">
        <h6 style={{color: "#333"}}>
          {props.title}
        </h6>
      </div>
      <div className="tac" style={{fontSize:"14px"}}>
        {props.description}
      </div>
    </div>
  );
};


const Guide = () => {
  return (
    <div className="container mt-5 pt-5">
      <div className="">
        <div className="tac fs-3">
          <text>چرا با ماشین نو ؟</text>
        </div>
        <div className="khati"/>
      </div>

      <div className="row mt-4">
        <GuideRow
          title={"بیش از چند میلیون بازدید سالانه"}
          description={"کالای شما به بیش از چند میلیون نفر نشان داده می‌شود"}
          icon={"ti ti-eye-check"}
        />
        <GuideRow
          title={"گزارش فروش و درآمد شفاف"}
          description={"دسترسی لحظه‌ای به آمار فروش و درآمد و امکان تسویه زود هنگام"}
          icon={"ti ti-cash-banknote"}
        />

        <GuideRow
          title={"امکان ارسال توسط ماشین نو و فروشنده"}
          description={"بسته‌بندی و ارسال را خودتان انجام دهید یا به ماشین نو بسپارید"}
          icon={"ti ti-send"}
        />
        <GuideRow
          title={"ثبت نام رایگان به همراه آموزش"}
          description={"ثبت نام و استفاده رایگان از پنل فروشندگان به همراه آموزش"}
          icon={"ti ti-login"}
        />


        <GuideRow
          title={"تبلیغات رایگان برای بازدید بیشتر محصول شما"}
          description={"ماشین نو بوسیله تبلیغات کلیکی فروش شما را افزایش می‌دهد"}
          icon={"ti ti-social"}
        />
        <GuideRow
          title={"فروش آنلاین در هر نقطه از ایران"}
          description={"در تمام هفته، 24 ساعته سفارش دریافت کنید و بفروشید"}
          icon={"ti ti-current-location"}
        />


      </div>
    </div>

  );
};

export default Guide;