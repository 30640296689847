import React from 'react';
import {validateNumber} from "../../../commons/public-validation";
import {isNumeric} from "../../../commons/numberHelper";
import xToast from "../../../commons/xToast";
import {webRequest} from "../../../Infrastructure/services/apiService";
import useAppContext from "../../../Infrastructure/state/useAppContext";
import confirmDialog, {dismissConfirmDialog, showConfirmDialog} from "../../../hooks/confirmDialog";
import {validateAuth, validateResponse} from "../../../Infrastructure/services/validateResponse";

const PriceManagementPage = () => {

  const [increased, setIncreased] = React.useState(0);
  const [decreased, setDecreased] = React.useState(0);
  let {state} = useAppContext();


  const confirmAndDecrease = () => {
    try {
      validateNumber(decreased, "درصد افزایش قیمت", {min: 1, max: 10});

      showConfirmDialog({
        title: "کاهش  قیمت ها",
        height: 180,
        description: "همه محصولات شما به اندازه  " + decreased + " درصد " + "کاهش پیدا می کند ، ادامه می دهید؟",
        onConfirmButtonClicked: () => {
          let toast = xToast.loading("لطفا صبر کنید")

          webRequest({
            url: "/price/decrease",
            auth: state.auth,
            data: {
              percent: decreased
            }
          }).then(response => {
            try {
              validateResponse(response)
              toast.updateAndDismiss("success", "عملیات با موفقیت انجام شد")
            } catch (e: any) {
              toast.updateAndDismiss("error", e.message)
            }

          })

          dismissConfirmDialog()
        }
      })




    } catch (e: any) {
      xToast.error(e.message);
      return;
    }

  }

  const confirmAndIncrease = () => {
    try {

      validateNumber(increased, "درصد افزایش قیمت", {min: 1, max: 10});

      showConfirmDialog({
        title: "افزایش قیمت ها",
        height: 180,
        description: "همه محصولات شما به اندازه  " + increased + " درصد " + "افزایش پیدا می کند ، ادامه می دهید؟",
        onConfirmButtonClicked: () => {
          let toast = xToast.loading("لطفا صبر کنید")

          webRequest({
            url: "/price/increase",
            auth: state.auth,
            data: {
              percent: increased
            }
          }).then(response => {
            try {
              validateResponse(response)
              toast.updateAndDismiss("success", "عملیات با موفقیت انجام شد")
            } catch (e: any) {
              toast.updateAndDismiss("error", e.message)
            }

          })

          dismissConfirmDialog()
        }
      })




    } catch (e: any) {
      xToast.error(e.message);
      return;
    }


  }


  return (
    <>
      <div className="card p-2">
        <div className={"card-header pt-1 pb-2 ps-1"} style={{borderBottom: "1px solid #eee"}}>افزایش همه قیمت های من</div>
        <div className={"card-body pt-2 ps-1 pe-1"}>
          درصد افزایش قیمت
          <div>
            <input type="tel" value={increased} onChange={(e: any) => setIncreased(e.target.value)} className={"form-control mt-1 tac"}/>
          </div>
          <span className={"btn btn-primary mt-2"} onClick={confirmAndIncrease}>تایید و افزایش قیمت</span>
        </div>
      </div>


      <div className="card p-2 mt-4">
        <div className={"card-header pt-1 pb-2 ps-1"} style={{borderBottom: "1px solid #eee"}}>کاهش همه قیمت های من</div>
        <div className={"card-body pt-2 ps-1 pe-1"}>
          درصد کاهش قیمت
          <div>
            <input type="tel" value={decreased} onChange={(e: any) => setDecreased(e.target.value)} className={"form-control mt-1 tac"}/>
          </div>
          <span className={"btn btn-primary mt-2"} onClick={confirmAndDecrease}>تایید و کاهش قیمت</span>
        </div>
      </div>


    </>
  );
};

export default PriceManagementPage;