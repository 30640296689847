import React, {useEffect, useState} from 'react';
import {webRequest} from "../../../Infrastructure/services/apiService";
import {Paginator} from "../../../Infrastructure/services/Types/Paginator";
import {ProductLite} from "../../../Infrastructure/services/Types/Product";

import "./styles.css"
import ClickablePagination from "../../Ui/ClickablePagination";
import DesktopMode from "./DesktopMode";
import MobileMode from "./MobileMode";
import DesktopSkeleton from "./DesktopSkeleton";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {isNumeric} from "../../../commons/numberHelper";
import XSelect from "../../Ui/Select/XSelect";
import MobileSkeleton from "./MobileSkeleton";
import {buildProductPageUri, buildSelectCategoryCar} from "./commons";
import useAppContext from "../../../Infrastructure/state/useAppContext";
import {validateAuth, validateResponse} from "../../../Infrastructure/services/validateResponse";
import xToast from "../../../commons/xToast";
import PriceListDialog from "./Price/PriceListDialog";
import AddPriceDialog from "./Price/AddPriceDialog";
import EditPriceDialog from "./Price/EditPriceDialog";

interface Props {
  brands: any[],
  categories: any[],
  cars: any[],
  type: any
}


const ProductPage = (props: Props) => {
  // let {state, dispatch} = useAppContext()
  let [response, setResponse] = useState<Paginator<ProductLite>>()

  let [loaded, setLoaded] = useState(false)
  let [width, setWidth] = useState(window.innerWidth)
  let navigate = useNavigate();
  let location = useLocation()

  let {state, dispatch} = useAppContext()

  let [brand, setBrand] = useState<number>(-1)
  let [car, setCar] = useState<number>(-1)
  let [page, setPage] = useState<number>(-1)
  let [category, setCategory] = useState<number>(-1)
  let [keyword, setKeyword] = useState<string>("")
  const [searchParams] = useSearchParams();

  const [priceProduct, setPriceProduct] = useState<ProductLite | null>(null)
  const [addPriceProduct, setAddPriceProduct] = useState<ProductLite | null>(null)
  const [editPrice, setEditPrice] = useState<any | null>(null)


  useEffect(() => {

    let page: any = searchParams.get("page");
    if (!isNumeric(page)) page = 1;

    let brand: any = searchParams.get("brand");
    if (!isNumeric(brand)) brand = -1;

    let car: any = searchParams.get("car");
    if (!isNumeric(car)) car = -1;

    let category: any = searchParams.get("category");
    if (!isNumeric(category)) category = -1;

    let keyword: any = searchParams.get("q");
    if (!keyword) keyword = "";
    window.addEventListener("resize", () => setWidth(window.innerWidth));


    setBrand(parseInt(brand));
    setCar(parseInt(car));
    setCategory(parseInt(category));
    setPage(parseInt(page));
    setKeyword(keyword);
    search(brand, car, category, page, keyword)

  }, [location.search,location.pathname]);

  const search = (brand: number, car: number, category: number, page: number, keyword: string) => {
    setLoaded(false)
    webRequest({
      url: "/products/all",
      auth: state.auth,
      data: {
        page, brand, car, category, keyword, type: props.type
      },
    }).then(response => {
      try {
        validateAuth(response, dispatch)
        validateResponse(response)
        setResponse(response.payload)
        setLoaded(true)
      } catch (e: any) {
        xToast.error(e.message)
      }

    })
  }


  const pageClicked = (page: number) => {
    setPage(page)
    let url = buildProductPageUri(brand, car, category, page, keyword,props.type!)
    navigate(url)
  }


  let _page = 1
  if (page > 0) _page = parseInt(page + "")

  const onKeyword = (keyword: string) => {
    setKeyword(keyword)
    setPage(1)
    let url = buildProductPageUri(brand, car, category, 1, keyword,props.type)
    navigate(url)
  }

  const brandSelected = (data: any) => {
    let brand = data.value
    setCar(-1)
    setPage(1)
    setBrand(data.value)

    let url = buildProductPageUri(brand, -1, category, 1, keyword,props.type)
    navigate(url)
  }

  const carSelected = (data: any) => {
    let car = data.value
    setCar(data.value)
    setPage(1)
    let url = buildProductPageUri(brand, car, category, 1, keyword,props.type)
    navigate(url)
  }


  const categorySelected = (data: any) => {
    let category = data.value
    setPage(1)
    setCategory(data.value)
    let url = buildProductPageUri(brand, car, category, 1, keyword,props.type)
    navigate(url)
  }


  let {cars, brandSelectValue, carSelectValue, categorySelectValue} = buildSelectCategoryCar(category, props, car, brand)

  const addPriceClicked = (product: ProductLite) => {
    setAddPriceProduct(product)
  }

  const confirmAddPrice = () => {
    setAddPriceProduct(null)
    setPriceProduct(null)
    search(brand, car, category, page, keyword)
  }


  const refresh = () => {
    search(brand, car, category, page, keyword)
  }

  const confirmEdit = () => {
    setEditPrice(-1)
    setPriceProduct(null)
  }


  return (
    <>
      <PriceListDialog onEditClicked={(id: any) => setEditPrice(id)} product={priceProduct} onCancel={() => setPriceProduct(null)} onAddPrice={addPriceClicked}/>
      <AddPriceDialog product={addPriceProduct} onConfirm={confirmAddPrice} onCancel={() => setAddPriceProduct(null)}/>

      {editPrice > 0 && <EditPriceDialog priceId={editPrice} onConfirm={() => {
        confirmEdit()
      }} onClose={() => {
        setTimeout(() => {
          setEditPrice(-1)
        }, 300)
      }}/>}


      <div className={"filter-container"}>
        <div className={"search-input"}>
          <label htmlFor="" style={{color: "#222"}}>جست و جو</label>
          <input value={keyword} onChange={e => onKeyword(e.target.value)} type="text" placeholder={"جست و جو در محصولات"} style={{width: "calc(100%)", borderRadius: "8px", outline: "0", border: "1px solid #ccc", paddingRight: "8px", height: "48px"}}/>
        </div>
        <div className="filter-select">
          <div>
            <span style={{color: "#222"}}>برند</span>
            <XSelect records={props.brands} optionSelected={brandSelected} defaultOption={brandSelectValue}/>
          </div>

          <div>
            <span style={{color: "#222"}}>خودرو</span>
            <XSelect  records={cars} optionSelected={carSelected} defaultOption={carSelectValue}/>
          </div>

          <div>
            <span style={{color: "#222"}}>دسته بندی</span>
            <XSelect  records={props.categories} optionSelected={categorySelected} defaultOption={categorySelectValue}/>
          </div>
        </div>
      </div>

      {width > 991 && <ClickablePagination
        lastPage={response?.lastPage}
        currentPage={_page}
        total={response?.total} clicked={pageClicked}/>}

      {!loaded && <>{width > 991 ? <DesktopSkeleton/> : null}</>}
      {!loaded && <>{width <= 991 ? <MobileSkeleton/> : null}</>}

      {loaded && <>

        {width > 991 ? <DesktopMode
            onRefresh={refresh}
            onAddPrice={(row: ProductLite) => setPriceProduct(row)}
            records={response?.records!}
          />
          :
          <MobileMode
            onRefresh={refresh}
            onAddPrice={(row: ProductLite) => setPriceProduct(row)}
            records={response?.records!}
          />}
      </>
      }
      <ClickablePagination lastPage={response?.lastPage} currentPage={_page} total={response?.total} clicked={pageClicked}/>

    </>
  );
};

export default ProductPage;