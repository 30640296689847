import React from "react";
import "./Header/bootstrap.rtl.min.css"
import "./Header/style.css"
import Header from "./Header/Header";
import Faq from "./Faq";
import Steps from "./Steps";
import Guide from "./Guide";
import FixedHeader from "./FixedHeader";
import Footer from "./Footer";

function Welcome() {
  return (
    <div id={"home-page"}>
      <FixedHeader/>
      <Header/>
      <Guide/>
      <Steps/>
      <Faq/>
      <br/>
      <br/>
      <br/>
      <br/>

      <Footer/>


    </div>
  );
}

export default Welcome;
