import React, {useState} from 'react';
import "./faq.css"


const FaqRow = ({title, description, isOpened, onClick}: { title: any, description: string, isOpened: boolean, onClick: Function }) => {
  return (
    <>
      <div className="card mb-3" style={{borderRadius: "15px"}}>
        <div className="card-header" onClick={() => onClick()} style={{borderBottom: isOpened ? "1px solid #ccc" : "none", padding: "16px", background: "#ecf0ff",borderTopLeftRadius:"15px",borderTopRightRadius:"15px",borderBottomRightRadius:isOpened?"0":"15px",borderBottomLeftRadius:isOpened?"0":"15px"}}>
          <h5 className="mb-0 cp" style={{fontSize: "18px", fontWeight: "bold", color: "#333"}}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              {title}
              <i className={"ti ti-chevron-down"} style={{color: "#275fe0", fontSize: "25px"}}></i>
            </div>
          </h5>
        </div>
        <div className={`collapse ${isOpened ? "show" : ""}`} style={{transition: "300ms"}}>
          <div className="card-body" style={{lineHeight: "36px", textAlign: "justify"}}>
            {description}
          </div>
        </div>
      </div>
    </>
  );
};

interface IFaq {
  id: number;
  title: string;
  description: string;
  isOpened: boolean;
}

const Faq = () => {

  let [faqs, setFaqs] = useState<IFaq[]>([
    {
      id: 1,
      title: "شرایط ثبت نام فروشنده در ماشین نو چیست؟",
      description: "تمامی اشخاص حقیقی و حقوقی که تولیدکننده، بازرگان یا دارنده کالا باشند می‌توانند به عنوان فروشنده در سایت ماشین نو ثبت‌نام نمایند و پس از احراز هویت و دریافت پنل فروشندگان کالای خود را در معرض فروش قرار دهند. لازم به ذکر است کارکرد این پنل بسیار ساده است و نیازی به تخصص خاصی ندارد",
      isOpened: false,
    },
    {
      id: 2,
      title: "برای ثبت‌نام چه مدارکی نیاز است؟",
      description: " برای فروشنده‌ی حقیقی: تصویر کارت ملی" +
        "                  برای فروشنده‌ی حقوقی: تصاویر ثبت‌نام در وب‌سایت «evat.ir»، روزنامه رسمی شرکت و کارت ملی صاحبین امضا",
      isOpened: false,
    },
    {
      id: 3,
      title: "در مورد قرارداد سوال دارم!!",
      description: " بعد از تایید ثبت‌نام یک دوره آموزش در اختیار شما قرار می‌گیرد، در صورت مدیریت صحیح پنل همکاری بسیار فروش در ماشین نو ساده خواهد بود. لازم به ذکر است تا قبل از آغاز اولین فروش هیچگونه تعهد قانونی شامل حال شما نمی‌شود",
      isOpened: false,
    },
    {
      id: 4,
      title: " برای ارسال سفارش چه کاری باید انجام دهم؟",
      description: "پس از ثبت سفارش توسط مشتری، برای شما پیامکی حاوی مراجعه به پنل کاربر ارسال خواهد شد که می‌توانید سفارش‌های ثبت شده را مشاهده و انجام دهید.",
      isOpened: false,
    },
    {
      id: 5,
      title: "آیا وارد کردن کد رهگیری مالیاتی اجبار است ؟",
      description: "برای اشخاص حقوقی، بله!",
      isOpened: false,
    },
    {
      id: 6,
      title: " تسویه حساب ماشین نو با تامین‌کنندگان چگونه است؟",
      description: "تمامی کالاهای فروخته شده ۱ روز کاری پس از تحویل کالا توسط مامور ارسال به مشتری تسویه خواهد شد.",
      isOpened: false,
    },

    {
      id: 7,
      title: "نام فروشگاه یا فروشنده چیست؟",
      description: "نام تجاری به معنای نامی است که فروشنده برای پنل فروش خود انتخاب می‌نماید و می‌بایست نامی به غیر از نام اصلی",
      isOpened: false,
    },


  ]);

  const clicked = (faq: IFaq) => {
    let records: IFaq[] = [];

    faqs.map((row: IFaq) => {
      if (row.id == faq.id) {
        if (row.isOpened) records.push({...row, isOpened: false})
        else records.push({...row, isOpened: true})
      } else records.push({...row, isOpened: false})
    })
    setFaqs(records);
  }


  return (
    <>
      <div className="container mt-5  pt-5">
        <div className="faq-parent">
          <div className="faq-title">
            <div className="text-center">
              <svg
                data-v-673b20b6=""
                fill="none"
                height={41}
                viewBox="0 0 43 41"
                width={43}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.88281 20.4998C3.88281 12.4467 3.88281 8.4201 6.46279 5.9183C9.04277 3.4165 13.1952 3.4165 21.5 3.4165C29.8048 3.4165 33.9572 3.4165 36.5372 5.9183C39.1172 8.4201 39.1172 12.4467 39.1172 20.4998C39.1172 28.553 39.1172 32.5796 36.5372 35.0814C33.9572 37.5832 29.8048 37.5832 21.5 37.5832C13.1952 37.5832 9.04277 37.5832 6.46279 35.0814C3.88281 32.5796 3.88281 28.553 3.88281 20.4998Z"
                  stroke="#3275F2"
                  strokeWidth="3.3"
                />
                <path
                  d="M17.4907 14.8877C17.4907 12.7406 19.2857 11 21.4999 11C23.7141 11 25.5091 12.7406 25.5091 14.8877C25.5091 16.313 24.718 17.5593 23.5384 18.236C22.522 18.8191 21.4999 19.7037 21.4999 20.8488V21.8855"
                  stroke="#3275F2"
                  strokeLinecap="round"
                  strokeWidth="3.3"
                />
                <ellipse
                  cx="21.5"
                  cy="27.3333"
                  fill="#3275F2"
                  rx="1.76172"
                  ry="1.70833"
                />
              </svg>
            </div>
            <h4 className="text-center fw-semibold my-3">سوالات پرتکرار شما</h4>
          </div>
          <div className="accordion-style" id="accordion">

            {faqs.map((faq: IFaq) => (
              <FaqRow
                key={faq.id}
                title={faq.title}
                description={faq.description}
                isOpened={faq.isOpened}
                onClick={() => clicked(faq)}/>
            ))}


          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;