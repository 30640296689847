import React from 'react';
import "./steps.css"


const Step = ({title, icon,step}: { step:number,title: string, icon: string }) => {
  return (

    <div className={"icon-container"}>
      <div className="icon-box">
        <span className={"step-number"}>{step}</span>
        <i className={icon} style={{marginRight:"auto !important"}}></i>
      </div>

      <div style={{position: "relative", top: "20px",height:"48px",color:"#222"}}>
        {title}
      </div>
    </div>

  );
};


const Steps = () => {
  return (
    <div className="container steps">
      <img src="/landing/arrow.svg" className={"step-image"} alt=""/>
      <div className={"container d-flex steps-main pr"} style={{padding:"0"}}>
        <div className={"dashed"}></div>
        <Step icon={"ti ti-login"} step={1} title={"ثبت نام در پنل"}/>
        <Step icon={"ti ti-book"} step={2} title={"آموزش نحوه کار با پنل"}/>
        <Step icon={"ti ti-file-pencil"} step={3} title={"ثبت محصول و ایجاد فروشگاه"}/>
        <Step icon={"ti ti-building-store"} step={4} title={"آغاز فعالیت در ماشین نو"}/>
      </div>

    </div>
  );
};

export default Steps;